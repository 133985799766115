<template>
	<div>
	<ContentHeader title="Data Jadwal Shift" />
     <div class="content-header">
		<div class="container-fluid">
			<div class="row justify-content-center">
	            <div class="col">
	            	<div class="card">
	            		<div class="card-header row d-flex align-items-center">
	            			<h3 class="card-title col-6">Data Jadwal Shift</h3>
	            			<div class="col-6 text-right">
	            				<router-link to="/tambah-jadwal-shift"><button class="btn btn-sm btn-info">Tambah</button></router-link>	
	            			</div>
	            		</div>
	            		<div class="card-body">
	            			<table class="table table-bordered table-hover mt-4">
							  <thead>
							    <tr>
							      <th scope="col" class="text-sm" style="width: 60px;">NO</th>
							      <th scope="col" class="text-sm">NAMA</th>
							      <th scope="col" class="text-sm">JAM MULAI</th>
							      <th scope="col" class="text-sm">JAM SELESAI</th>
							      <th scope="col" class="text-sm" style="width: 100px;">ACTION</th>
							    </tr>
							  </thead>
							  <tbody>
							    <tr v-for="(row, index) in data_result" :key="row.id">
							      <td class="text-sm" scope="row">{{ index + 1 }}</td>
							      <td class="text-sm">{{ row.nama }}</td>
							      <td class="text-sm">{{ dayjs(row.mulai).format('HH:mm:ss') }}</td>
							      <td class="text-sm">{{ dayjs(row.selesai).format('HH:mm:ss') }}</td>
							      <td class="text-sm">
							      	<div class="btn-group">
                                        <button type="button" class="btn btn-sm btn-info text-sm">Proses</button>
                                        <button type="button" class="btn btn-sm btn-info dropdown-toggle dropdown-icon" data-toggle="dropdown" aria-expanded="false">
                                        <span class="sr-only"></span>
                                        </button>
                                        <div class="dropdown-menu" role="menu" style="">
                                            <router-link :to="`/edit-jadwal-shift/${row.id}`" class="dropdown-item text-sm">Edit</router-link>
                                            <div class="dropdown-divider"></div>
                                            <button @click="konfirmasiHapus(row.id)" class="dropdown-item text-danger">Hapus</button>
                                        </div>
                                    </div>
							      </td>
							    </tr>
							  </tbody>
							</table>
							<p class="text-center mt-4" v-if="data_result == null">Data Masih Kosong</p>
	            		</div>
	            		<div class="card-footer">
							
	            		</div>
	            	</div>
	            </div>
	        </div>
		</div>
	</div>
    </div>
</template>

<script setup>
import ContentHeader from '@/components/ContentHeader'
import { computed } from 'vue'
import store from '@/store'
import * as dayjs from 'dayjs'

import axios from 'axios' 
import Swal from 'sweetalert2'
// import router from '@/router'

// Data
const cabang 	  = computed(() => store.getters['auth/cabang'])
const data_result = computed(() => store.getters['jadwalShift/getJadwalShift']) 

// Fetch data
store.dispatch('jadwalShift/fetchJadwalShift', cabang.value.id)

const konfirmasiHapus = (id) => {
    Swal.fire({
      title: 'Hapus Data?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: `Ya`,
      cancelButtonText: `Batal`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        hapusData(id)
      } 
    })
  }

const hapusData = (id) => {
	axios.delete(`api/jadwal_shift/destroy/${id}`)
	.then(() => {
		Swal.fire({
			title: 'Berhasil!',
			text: 'Berhasil Menghapus Data',
			icon: 'success',
			showConfirmButton: false,
			timer: 1500
		})
		  
		store.dispatch('jadwalShift/fetchJadwalShift', cabang.value.id)
	})
	.catch(() => {
		Swal.fire({
			title: 'Gagal!',
			text: 'Gagal menghapus data',
			icon: 'error',
            showConfirmButton: false,
            timer: 1500
		})
	})
}

</script>
